import React, { useContext } from 'react';

import { Dropdown, DropdownContext } from '@admin/molecules/RichEditor/Editor/atoms';
import {
    ElementFormat,
    ElementFormatType,
    ToolbarButton,
    ToolbarList,
} from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';

import styles from './AlignmentSelector.module.scss';

export const AlignmentSelector = () => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const { toggle } = useContext(DropdownContext);

    return (
        <div className={styles.AlignmentSelector}>
            <ToolbarButton aria-label={__translate('select_alignment')} onClick={toggle}>
                <Icon.textLeft />
                <Icon.chevronDown />
            </ToolbarButton>

            <Dropdown>
                <ToolbarList>
                    <ElementFormat format={ElementFormatType.left} onClick={toggle} />
                    <ElementFormat format={ElementFormatType.center} onClick={toggle} />
                    <ElementFormat format={ElementFormatType.right} onClick={toggle} />
                    <ElementFormat format={ElementFormatType.justify} onClick={toggle} />
                </ToolbarList>
            </Dropdown>
        </div>
    );
};
