export enum TranslationKey {
    pages = 'pages',
    propositions = 'propositions',
    tierGroups = 'tier-groups',
    tiers = 'tiers',
    richEditor = 'rich-editor',
    curation = 'curation',
    common = 'common',
    components = 'components',
}
