import { FC, ReactNode } from 'react';

import styles from '@admin/molecules/Modal/Modal.module.scss';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { BaseModalType } from '@admin/organisms/BaseModal/BaseModalProvider';
import { useModalContext } from '@admin/organisms/BaseModal/useModalContext';

export type ContextKeyBaseModalType = BaseModalType | CustomNodeTypes | string;

interface Props {
    children: ReactNode | ReactNode[];
    contextKey?: ContextKeyBaseModalType;
    className?: string;
}

export enum ModalActions {
    create = 'create',
    update = 'update',
}

export const BaseModal: FC<Props> = ({
    children,
    contextKey = BaseModalType.Default,
    className = '',
}: Props) => {
    const { state } = useModalContext(contextKey);

    const show = state?.show;
    const classNames = ['Modal', styles.Modal, 'shown', styles.shown, className];
    if (!show) return null;

    return (
        <aside className={classNames.join(' ')}>
            <section>{children}</section>
        </aside>
    );
};
