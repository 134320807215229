import React, { ReactNode } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_ELEMENT_COMMAND } from 'lexical';

import { ToolbarButton } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';

import { ElementFormatType } from '.';

import styles from './ElementFormat.module.scss';

interface Props {
    format: ElementFormatType;
    onClick?: () => void;
}

const icons: {
    left: ReactNode;
    center: ReactNode;
    right: ReactNode;
    justify: ReactNode;
} = {
    left: <Icon.textLeft />,
    center: <Icon.textCenter />,
    right: <Icon.textRight />,
    justify: <Icon.textJustify />,
};

export const ElementFormat = ({ format, onClick = () => {} }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const [editor] = useLexicalComposerContext();
    const icon = icons[format];

    const handleOnClick = () => {
        onClick();

        return editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, format);
    };

    return (
        <ToolbarButton
            className={styles.ElementFormat}
            aria-label={__translate(`format_${format}`)}
            onClick={handleOnClick}
        >
            {icon}
            <span className={styles.text}>{__translate(`format_${format}`)}</span>
        </ToolbarButton>
    );
};
