export enum AdminRoute {
    Ads = 'ads',
    AdsTxt = 'ads-txt',
    Advice = 'advice',
    AiPrompts = 'ai-prompts',
    AiResults = 'ai-results',
    Articles = 'articles',
    Article = 'article/[...slug]',
    AuditLogs = 'audit-logs',
    Banner = 'banner',
    BlockedDomain = 'blocked-domain',
    BlockedIps = 'blocked-ips',
    Bookmakers = 'bookmakers/[[...status]]',
    Bottom = 'bottom',
    CarouselHeadlines = 'carousel-headlines',
    Curation = 'curation',
    Comments = 'comments/[...tab]',
    Experiments = 'experiments',
    FilteredComments = 'filtered-comments',
    Flag = 'flag',
    ForbiddenWords = 'forbidden-words',
    Gebruiker = 'gebruiker/[[...index]]',
    Hoofdpagina = 'hoofdpagina',
    Index = '/',
    Jersey = 'jersey',
    Link = 'links/[id]',
    Links = 'links',
    Liveness = 'liveness',
    Logs = 'logs',
    MatchEvents = 'match-events',
    MatchReports = 'match-reports',
    MatchTicker = 'match-ticker',
    MediaNews = 'media/[[...newsID]]',
    MediaVideofeed = 'media/videofeed/[[...status]]',
    ModuleSchedule = 'module-schedule/[[...tab]]',
    NavItems = 'nav-items',
    NewsCategory = 'news-category',
    NotificationBar = 'notificationbar',
    OddsDossier = 'odds-dossier',
    OptaExports = 'opta-exports',
    Pages = 'pages',
    PagesEdit = 'pages/edit/[...slug]',
    PagesNew = 'pages/new',
    Poll = 'poll',
    PropositionCreate = 'propositions/new',
    Proposition = 'propositions/edit/[...propoistionsID]',
    Redirects = 'redirects',
    Rooster = 'rooster',
    Sandbox = 'sandbox',
    Stats = 'stats',
    Tag = 'tags/[[...tagType]]',
    TagsManage = 'tags/manage/[tagID]',
    TagsDuplicate = 'tags/duplicate/[tagID]',
    TierGroups = 'tier-groups',
    Tiers = 'tiers',
    Tier = 'tiers/edit/[...tierID]',
    TierCreate = 'tiers/new',
    Users = 'users',
}
