import { ChangeEvent } from 'react';

import { RadioButton } from '@admin/molecules/InputElement/atoms/RadioButton';
import { FieldType } from '@admin/types';

import styles from './RadioButtons.module.scss';

export interface RadioOption {
    value: string;
    label: string;
}

interface Props {
    id: string;
    name: string;
    type: FieldType.radioButtons;
    onChange: (value: string) => void;
    value?: string;
    options: RadioOption[];
}

export const RadioButtons = ({ onChange, value, options }: Props) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

    return (
        <div className={styles.RadioButtons}>
            {options.map((option, index) => (
                <RadioButton
                    checked={option.value === value}
                    key={index}
                    {...option}
                    onChange={handleOnChange}
                />
            ))}
        </div>
    );
};
