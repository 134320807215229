import { useEffect, useState } from 'react';

import { TeamVsTeam } from '@admin/atoms/TeamVsTeam';
import { ArticleBlock } from '@admin/molecules/RichEditor/Editor/atoms/ArticleBlock';
import { getMatch } from '@admin/molecules/RichEditor/Editor/helpers';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import { ModalActions, useModalContext } from '@admin/organisms/BaseModal';
import { Match } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { MatchDetailSectionModal, MatchDetailSectionNodeData } from '.';

import styles from './MatchDetailSectionArticleBlock.module.scss';

interface Props extends MatchDetailSectionNodeData {
    onChange: (data: MatchDetailSectionNodeData) => void;
    onDelete: () => void;
}

export const MatchDetailSectionArticleBlock = ({ onChange, onDelete, matchID, showOdds, type }: Props) => {
    const { openModal } = useModalContext(CustomNodeTypes.matchDetailSection);
    const contextData = useContextData();
    const [match, setMatch] = useState<Match | null>();

    useEffect(() => {
        getMatch({ contextData, matchID }).then(setMatch);
    }, [matchID, contextData]);

    if (!match) {
        return null;
    }

    return (
        <div className={`theme-admin ${styles.MatchDetailSectionArticleBlock}`}>
            <ArticleBlock
                onClick={openModal}
                onDelete={onDelete}
                customNodeType={CustomNodeTypes.matchDetailSection}
            >
                <TeamVsTeam
                    className={styles.teamVsTeam}
                    kickOff={match.kickOff}
                    awayTeamName={match.away?.name}
                    homeTeamName={match.home?.name}
                />
            </ArticleBlock>
            <MatchDetailSectionModal
                formData={{ matchID, showOdds, type }}
                onSubmit={onChange}
                action={ModalActions.update}
            />
        </div>
    );
};
