import React, { useCallback, useContext, useEffect, useState } from 'react';
import { $isLinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
    $getSelection,
    $isRangeSelection,
    COMMAND_PRIORITY_LOW,
    EditorState,
    SELECTION_CHANGE_COMMAND,
} from 'lexical';

import { Dropdown, DropdownContext } from '@admin/molecules/RichEditor/Editor/atoms';
import { getSelectedNode } from '@admin/molecules/RichEditor/Editor/helpers';
import { LinkEditor, ToolbarButton } from '@admin/molecules/RichEditor/Editor/plugins/ToolbarPlugin/atoms';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';

import styles from './InsertLink.module.scss';

export const InsertLink = () => {
    const __translate = useTranslation(TranslationKey.richEditor).t;
    const [editor] = useLexicalComposerContext();
    const { toggle } = useContext(DropdownContext);
    const [isLink, setIsLink] = useState<boolean>(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }
        }
    }, [setIsLink]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }: { editorState: EditorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateToolbar();
                    return false;
                },
                COMMAND_PRIORITY_LOW,
            ),
        );
    }, [editor, updateToolbar]);

    return (
        <div className={styles.InsertLink}>
            <ToolbarButton aria-label={__translate('insert_link')} onClick={toggle} filled={isLink}>
                <Icon.textLink />
            </ToolbarButton>
            <Dropdown>
                <LinkEditor />
            </Dropdown>
        </div>
    );
};
