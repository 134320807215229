import { ChangeEvent } from 'react';

import styles from './RadioButton.module.scss';

interface Props {
    label: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    checked: boolean;
}

export const RadioButton = ({ label, onChange, value, checked }: Props) => {
    return (
        <label className={styles.RadioButton}>
            <input type="radio" value={value} checked={checked} onChange={onChange} />
            {label}
        </label>
    );
};
