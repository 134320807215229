import { Match, MatchService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData } from '@common/defaults';

export const getMatch = ({
    contextData,
    matchID,
}: {
    contextData: ContextData;
    matchID: string;
}): Promise<Match> =>
    ApiService<MatchService>({
        contextData,
        isClientSide: true,
        service: MatchService,
    }).getMatchDetailsMatch({ id: matchID });
