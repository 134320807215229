import { useState } from 'react';

import { Button, ButtonType } from '@admin/atoms/Button';
import { TeamVsTeam } from '@admin/atoms/TeamVsTeam';
import { dataSourceEnvelope } from '@admin/molecules/AutoComplete/AutoComplete';
import { getMatches } from '@admin/molecules/RichEditor/Editor/helpers';
import { CustomNodeTypes } from '@admin/molecules/RichEditor/Editor/plugins/CustomPluginNode';
import {
    BaseModal,
    BaseModalFooter,
    BaseModalHeader,
    ModalActions,
    useModalContext,
} from '@admin/organisms/BaseModal';
import { Form } from '@admin/organisms/Form';
import FlashMessageUtil from '@admin/utils/FlashMessageUtil/FlashMessageUtil';
import { TranslationKey } from '@admin/utils/TranslationKey';
import { useTranslation } from '@cms/i18n';
import { Match } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { formFields } from './helpers';
import { MatchBlockNodeData } from '.';

interface Props {
    formData?: MatchBlockNodeData;
    onSubmit: (data: MatchBlockNodeData) => void;
    action?: ModalActions;
}

export const MatchBlockModal = ({ formData, onSubmit, action = ModalActions.create }: Props) => {
    const __translate = useTranslation(TranslationKey.richEditor).t;

    const { closeModal } = useModalContext(CustomNodeTypes.matchBlock);
    const contextData = useContextData();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const flashes = FlashMessageUtil(__translate);
    const mapMatchesToSourceEnvelope = (match: Match) => ({
        text: match.id,
        element: (
            <TeamVsTeam
                kickOff={match.kickOff}
                awayTeamName={match.away?.name}
                homeTeamName={match.home?.name}
            />
        ),
    });

    const dataSource = async (searchText: string): Promise<dataSourceEnvelope[]> =>
        getMatches(contextData, searchText).then((matches = []) => matches.map(mapMatchesToSourceEnvelope));

    const handleSubmit = async (data: MatchBlockNodeData) => {
        if (!formData?.matchID && !data.matchID) {
            setIsSubmitting(false);
            flashes.customError('insertMatchBlock.missingMatchID');
            return;
        }

        const nodeData: MatchBlockNodeData = {
            matchID: data.matchID || formData?.matchID || '',
            type: CustomNodeTypes.matchBlock,
        };

        onSubmit(nodeData);
        setIsSubmitting(false);
        closeModal();
    };

    const clickButton = () => {
        setIsSubmitting(true);
        doSubmit();
    };

    let doSubmit: () => void;
    const bindDoSubmit = (callback: () => void) => {
        doSubmit = callback;
    };

    return (
        <div className="theme-admin">
            <BaseModal contextKey={CustomNodeTypes.matchBlock}>
                <BaseModalHeader contextKey={CustomNodeTypes.matchBlock}>
                    {__translate('insertMatchBlock.title')}
                </BaseModalHeader>
                <Form<MatchBlockNodeData, MatchBlockNodeData>
                    id={'match-block'}
                    action={`match-block-${action}`}
                    handleSubmit={handleSubmit}
                    bindDoSubmit={bindDoSubmit}
                    formFields={formFields(dataSource)}
                    entity={formData}
                />
                <BaseModalFooter className={'flex justify-between'}>
                    <Button
                        className={'w-full'}
                        filled={true}
                        loading={isSubmitting}
                        type={ButtonType.button}
                        onClick={clickButton}
                    >
                        {__translate(`insertMatchBlock.${action}`)}
                    </Button>
                </BaseModalFooter>
            </BaseModal>
        </div>
    );
};
