import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { SectionHeader } from '@admin/atoms/SectionHeader';
import { Switch } from '@admin/atoms/Switch';
import { Action } from '@admin/interfaces/Action';
import { AutoComplete, AutoCompleteProps } from '@admin/molecules/AutoComplete';
import { DateRange } from '@admin/molecules/DateRange';
import { FileUpload, FileUploadProps } from '@admin/molecules/FileUpload';
import { LabelHolder, RadioButtons } from '@admin/molecules/InputElement/atoms';
import { RichEditor } from '@admin/molecules/RichEditor';
import { useTranslation } from '@cms/i18n';
import { logger } from '@common/logger';
import { DateTimeUtil, Format } from '@common/utils/DateTimeUtil';

import styles from './InputElement.module.scss';

interface ValueContainer {
    value: string | number | boolean;
}

function isValueContainer(object: any): object is ValueContainer {
    return object && typeof object === 'object' && 'value' in object;
}

export interface TranslationKeyHolder {
    translationKey: string;
}

export interface Option {
    value: string | number | null;
    title: string | TranslationKeyHolder;
}

export interface InputElementProps {
    actions: Action[];
    id: string;
    inputKey?: string;
    type?: Type | keyof typeof Type;
    title: string | TranslationKeyHolder | false;
    value?: string | number | boolean | ValueContainer;
    defaultValue?: string | number | boolean | ValueContainer;
    isMandatory?: boolean;
    isDisabled?: boolean;
    placeholder?: string | TranslationKeyHolder | true;
    options?: Option[];
    reset?: number;
    bindIsValid?: (key: string, callback: IsValidCallback) => void;
    extraInput?: Props;
    classes?: string[];
    onChange?: (value: unknown) => void;
}

export type Props = InputElementProps & Partial<FileUploadProps> & Partial<AutoCompleteProps>;

export enum Type {
    autoComplete,
    checkbox,
    date,
    dateRange,
    dateTime,
    email,
    file,
    hidden,
    label,
    number,
    paragraph,
    radio,
    select,
    sectionHeader,
    switch,
    switchInput,
    text,
    textarea,
    title,
    undefined,
    url,
    richEditor,
    radioButtons,
}

export type IsValidCallback = () => boolean;

export const InputElement: FC<Props> = (props) => {
    const typeAsString =
        props.type && isNaN(+props.type) ? props.type : Type[props.type as keyof typeof Type];
    const type = props.type && isNaN(+props.type) ? Type[props.type as keyof typeof Type] : props.type;

    let _value = props.value;
    if (
        (_value === null || _value === '' || typeof _value === 'undefined') &&
        typeof props.defaultValue !== 'undefined'
    ) {
        _value = props.defaultValue;
    }

    if (isValueContainer(_value)) {
        _value = _value.value;
    }

    if (type === Type.dateTime && _value) {
        _value = DateTimeUtil.format(_value.toString(), Format.DATE_TIME_PROGRAMMATIC);
    }

    const [value, setValue] = useState<string | number | boolean | undefined>(_value);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [isLabelShown, setIsLabelShown] = useState<boolean>(false);
    const input = useRef<HTMLInputElement>(null);

    /*
     * Update methods
     */
    const onInputChanged = () => {
        let value: string | number | boolean | undefined = input.current?.value;

        switch (type) {
            case Type.checkbox:
            case Type.radio:
            case Type.switch:
            case Type.switchInput:
                value = input.current?.checked;
                break;
        }

        logger.debug('InputElement::onInputChanged', value);
        setValue(value);
        onValidate(value);
        if (props.onChange) props.onChange(value);
    };

    const checkForLabel = useCallback(() => {
        logger.debug('InputElement::checkForLabel');
        const value: string | number | boolean | undefined = input.current?.value;
        const showLabel = value ? true : false;

        //show label anyway for fateRange elements
        if (type === Type.dateRange || type === Type.date) {
            setIsLabelShown(true);
        } else if (showLabel !== isLabelShown) {
            setIsLabelShown(showLabel);
        }
    }, [isLabelShown, type]);

    const onInput = useCallback(() => {
        checkForLabel();
        if (isValidated) {
            setIsValidated(false);
        }
    }, [checkForLabel, isValidated]);

    const onValidate = (_value: string | number | boolean | undefined): boolean => {
        let _isValid = true;
        if (value === undefined && props.type === 'number') {
            _isValid = !props.isMandatory;
        }

        if (!value && props.type !== 'number') {
            _isValid = !props.isMandatory;
        } else if (type === Type.url) {
            const pattern =
                /^(https:\/\/([(www\.)a-z0-9\u00a1-\ufff0-9@:%._\+~#='-]{2,256}\.[A-Za-z\u00a1-\ufff0-9{2,25}])|(http:\/\/\w*\.\w*\.(local)(:\d+)?\/)|\/)([A-Za-z0-9@:;%_\+\{\}\-.~#?!&\/'=,\[\]]*)$/i;
            _isValid = pattern.test(String(_value));
        } else if (type === Type.date) {
            const pattern = /^\d{4}(\-)(((0)[0-9])|((1)[0-2]))(\-)([0-2][0-9]|(3)[0-1])$/i;
            _isValid = pattern.test(String(_value));
        }

        if (isValid !== _isValid) {
            setIsValid(_isValid);
        }

        if (!isValidated) {
            setIsValidated(true);
        }

        return _isValid;
    };

    if (typeof value !== 'undefined' && !isValidated) {
        onValidate(value);
    }

    useEffect(() => {
        if (JSON.stringify(_value) !== JSON.stringify(value) && !isValueContainer(_value)) {
            setValue(_value);
        }
        checkForLabel();
        // only run if props.value changed
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isValueContainer(_value) && props.reset) {
            setValue(_value);
        }
        // only run if props.reset changed
    }, [props.reset]); // eslint-disable-line react-hooks/exhaustive-deps

    /*
     * Render
     */
    const __components = useTranslation('components').t;

    let { title } = props;
    if (!title && title !== false) {
        title = __components('InputElement.title.' + props.id);
    } else if (typeof title === 'object' && title.translationKey) {
        if (title.translationKey.indexOf(':') >= 0) {
            title = __components(title.translationKey);
        } else {
            title = __components('InputElement.title.' + title.translationKey);
        }
    }

    let placeholder = props.placeholder || '';
    if (placeholder === true) {
        placeholder = __components('InputElement.placeholder.' + props.id);
    } else if (typeof placeholder === 'object' && placeholder.translationKey) {
        if (placeholder.translationKey.indexOf(':') >= 0) {
            placeholder = __components(placeholder.translationKey);
        } else {
            placeholder = __components('InputElement.placeholder.' + placeholder.translationKey);
        }
    } else if (title) {
        placeholder = title;
    }

    if (props.isMandatory) {
        if (title) title += ' *';
        if (placeholder) placeholder += ' *';
    }

    const classes = props.classes ? [...props.classes] : [];
    classes.unshift(styles.InputElement);
    classes.push(typeAsString + '-type');

    if (!isValid) {
        classes.push(styles.error);
    }
    if (isLabelShown) {
        classes.push(styles['has-label']);
    }
    if (props.isDisabled) {
        classes.push(styles['disabled']);
    }

    const _props = {
        className: classes.join(' '),
    };

    const _inputProps: any = {
        id: props.inputKey ?? props.id,
        type: props.type ? typeAsString : 'text',
        name: props.id,
        placeholder: placeholder,
        ref: input,
        onInput: onInput,
        onChange: onInputChanged,
        onBlur: checkForLabel,
        readOnly: props.isDisabled,
    };

    switch (type) {
        case Type.dateTime:
            _inputProps.type = 'datetime-local';
            break;
    }

    if (props.bindIsValid) {
        const isValidCallback: IsValidCallback = (): boolean => (!isValidated ? onValidate(value) : isValid);
        props.bindIsValid(props.id, isValidCallback);
    }

    switch (type) {
        case Type.hidden:
            return <input {..._inputProps} value={value ?? ''} />;

        case Type.checkbox:
        case Type.radio:
            return (
                <div {..._props}>
                    <label htmlFor={props.inputKey ?? props.id}>
                        <>
                            <input {..._inputProps} checked={Boolean(value)} />
                            {title}
                        </>
                    </label>
                </div>
            );

        case Type.switch:
            return (
                <div {..._props}>
                    <label htmlFor={props.inputKey ?? props.id}>
                        <Switch _inputProps={_inputProps} value={Boolean(value)} />
                        {' ' + title}
                    </label>
                </div>
            );

        //TODO: change all label types to title
        case Type.label:
            return (
                <div {..._props}>
                    <b>{(props.value ?? title).toString()}</b>
                </div>
            );

        case Type.paragraph:
            return (
                <div {..._props}>
                    <p>{(props.value ?? title).toString()}</p>
                </div>
            );

        case Type.title:
            return (
                <div {..._props}>
                    <b>{(props.value ?? title).toString()}</b>
                </div>
            );

        case Type.textarea:
            return (
                <LabelHolder {..._props} inputKey={props.inputKey} id={props.id} title={title.toString()}>
                    <textarea
                        {..._inputProps}
                        value={value ?? ''}
                        onBlur={(e) => {
                            onValidate(e.target.value);
                        }}
                    />
                </LabelHolder>
            );

        case Type.file:
            return (
                <LabelHolder {..._props} inputKey={props.inputKey} id={props.id} title={title.toString()}>
                    <FileUpload {...(props as FileUploadProps)} />
                </LabelHolder>
            );

        case Type.select:
            delete _inputProps.onInput;
            return (
                <LabelHolder {..._props} inputKey={props.inputKey} id={props.id} title={title.toString()}>
                    <select value={value ?? ''} {..._inputProps}>
                        {props.options?.map((option) => {
                            let title = option.title;
                            if (typeof title === 'object') {
                                title = __components(title.translationKey);
                            }
                            return (
                                <option key={option.value} value={option.value ?? ''}>
                                    {title.toString()}
                                </option>
                            );
                        })}
                    </select>
                </LabelHolder>
            );

        case Type.date:
            return (
                <LabelHolder {..._props} inputKey={props.inputKey} id={props.id} title={title.toString()}>
                    <input
                        {..._inputProps}
                        min="1900-01-01"
                        max="2099-12-31"
                        value={value ?? ''}
                        onBlur={(e) => {
                            onValidate(e.target.value);
                        }}
                    />
                </LabelHolder>
            );

        case Type.dateRange:
            return (
                <LabelHolder {..._props} inputKey={props.inputKey} id={props.id} title={title.toString()}>
                    <DateRange inputProps={_inputProps} value={(value as string) || null} />;
                </LabelHolder>
            );

        case Type.autoComplete:
            return (
                <div {..._props}>
                    <label htmlFor={props.inputKey ?? props.id}>{title.toString()}</label>
                    <AutoComplete
                        {...props}
                        selectedItems={value}
                        dataSource={props.dataSource}
                        isMultiSelect={Boolean(props.isMultiSelect)}
                        inputProps={_inputProps}
                        minimumCharsCount={props.minimumCharsCount || 1}
                    />
                </div>
            );

        case Type.switchInput:
            return (
                <div {..._props}>
                    <b>{title.toString()}</b>
                    <div className="wrapper">
                        <Switch _inputProps={_inputProps} value={Boolean(value)} />
                        {Boolean(value) && props.extraInput ? <InputElement {...props.extraInput} /> : null}
                    </div>
                </div>
            );

        case Type.sectionHeader:
            return (
                <div {..._props}>
                    <SectionHeader {...props} value={value} title={title.toString()} />
                </div>
            );
        case Type.richEditor:
            return (
                <RichEditor
                    {..._inputProps}
                    value={value}
                    onBlur={(currentValue: string) => onValidate(currentValue)}
                    onChange={(changeValue: string) => {
                        onValidate(changeValue);
                        if (props.onChange) props.onChange(changeValue);
                    }}
                ></RichEditor>
            );
        case Type.radioButtons:
            return (
                <LabelHolder {..._props} inputKey={props.inputKey} id={props.id} title={title.toString()}>
                    <RadioButtons
                        {..._inputProps}
                        value={value}
                        options={props.options || []}
                        onChange={(changeValue: string) => {
                            onValidate(changeValue);
                            if (props.onChange) props.onChange(changeValue);
                        }}
                    ></RadioButtons>
                </LabelHolder>
            );
        default:
            return (
                <LabelHolder {..._props} inputKey={props.inputKey} id={props.id} title={title.toString()}>
                    <input
                        {..._inputProps}
                        value={value ?? ''}
                        onBlur={(e) => {
                            onValidate(e.target.value);
                        }}
                    />
                </LabelHolder>
            );
    }
};
