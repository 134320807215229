import { AdminRoute } from './routing/AdminRoute';

export const defaultNamespaces = ['actions', 'common', 'components', 'datetime', 'url', 'rich-editor'];

export const routeNamespaces: { [Key in AdminRoute]?: string[] } = {
    [AdminRoute.Index]: ['articles'],
    [AdminRoute.AdsTxt]: ['ads-txt'],
    [AdminRoute.Flag]: ['comments'],
    [AdminRoute.OptaExports]: ['opta-exports'],
    [AdminRoute.Poll]: ['poll'],
    [AdminRoute.Sandbox]: ['sandbox'],

    // Routes matching 'rgx:/ads'
    [AdminRoute.Ads]: ['ads'],

    // Routes matching 'rgx:/ai-prompts'
    [AdminRoute.AiPrompts]: ['ai-prompts'],

    // Routes matching 'rgx:/ai-results'
    [AdminRoute.AiResults]: ['ai-results'],

    // Routes matching 'rgx:/articles'
    [AdminRoute.Articles]: ['articles'],

    // Routes matching 'rgx:/artikel'
    [AdminRoute.Article]: ['articles', 'poll'],

    // Routes matching 'rgx:/audit-logs'
    [AdminRoute.AuditLogs]: ['audit-logs'],

    // Routes matching 'rgx:/blocked-domain'
    [AdminRoute.BlockedDomain]: ['blocked-domain'],

    // Routes matching 'rgx:/blocked-ips'
    [AdminRoute.BlockedIps]: ['blocked-ips'],

    // Routes matching 'rgx:/bookmaker'
    [AdminRoute.Bookmakers]: ['bookmaker'],

    // Routes matching 'rgx:/curation'
    [AdminRoute.Curation]: ['curation'],

    // Routes matching 'rgx:/comments'
    [AdminRoute.Comments]: ['comments', 'ip-qs', 'recaptcha'],

    // Routes matching 'rgx:/experiments'
    [AdminRoute.Experiments]: ['experiments'],

    // Routes matching 'rgx:/filtered-comments'
    [AdminRoute.FilteredComments]: ['filtered-comments'],

    // Routes matching 'rgx:/forbidden-words'
    [AdminRoute.ForbiddenWords]: ['forbidden-words'],

    // Routes matching 'rgx:/gebruiker'
    [AdminRoute.Gebruiker]: ['user'],

    // Routes matching 'rgx:/match-reports'
    [AdminRoute.MatchReports]: ['match-reports'],

    // Routes matching 'rgx:/match-ticker'
    [AdminRoute.MatchTicker]: ['match-ticker'],

    // Routes matching 'rgx:/module-schedule'
    [AdminRoute.ModuleSchedule]: ['module-schedule'],

    // Routes matching 'rgx:/nav-items'
    [AdminRoute.NavItems]: ['nav-items'],

    // Routes matching 'rgx:/news-category'
    [AdminRoute.NewsCategory]: ['news-category'],

    // Routes matching 'rgx:/notificationbar'
    [AdminRoute.NotificationBar]: ['notificationbar'],

    // Routes matching 'rgx:/odds-dossier'
    [AdminRoute.OddsDossier]: ['oddsdossier', 'module-schedule'],

    // Routes matching 'rgx:/pages'
    [AdminRoute.Pages]: ['pages'],
    [AdminRoute.PagesNew]: ['pages'],
    [AdminRoute.PagesEdit]: ['pages'],

    // Routes matching 'rgx:/propositions'
    [AdminRoute.PropositionCreate]: ['propositions'],
    [AdminRoute.Proposition]: ['propositions'],

    // Routes matching 'rgx:/redirects'
    [AdminRoute.Redirects]: ['redirects'],

    // Routes matching 'rgx:/tags'
    [AdminRoute.Tag]: ['tags', 'meta'],
    [AdminRoute.TagsManage]: ['tags', 'meta'],
    [AdminRoute.TagsDuplicate]: ['tags', 'meta'],

    // Routes matching 'rgx:/tiers'
    [AdminRoute.TierCreate]: ['tiers'],
    [AdminRoute.Tiers]: ['tiers'],
    [AdminRoute.Tier]: ['tiers'],

    // Routes matching 'rgx:/tier-groups'
    [AdminRoute.TierGroups]: ['tier-groups', 'tiers', 'propositions'],

    // Routes matching 'rgx:/users'
    [AdminRoute.Users]: ['user'],

    // Routes matching 'rgx:/videofeed'
    [AdminRoute.MediaVideofeed]: ['videofeed'],
};
