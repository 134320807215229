import { ReactNode } from 'react';

import styles from '@admin/molecules/InputElement/InputElement.module.scss';

interface Props {
    children: ReactNode;
    title: string;
    inputKey?: string;
    id?: string;
}

export const LabelHolder = ({ children, title, inputKey, id, ...rest }: Props) => {
    return (
        <div {...rest}>
            <div className={styles['label-holder']}>
                <label htmlFor={inputKey || id}>{title}</label>
            </div>
            {children}
        </div>
    );
};
