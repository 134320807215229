import { Translate } from '@cms/i18n';
import { EventHandler } from '@common/utils/EventHandler';

interface FlashMessages {
    deleteSuccess: () => void;
    deleteError: (error?: Error) => void;
    createSuccess: () => void;
    createError: (error?: Error) => void;
    updateSuccess: () => void;
    updateError: (error?: Error) => void;
    customSuccess: (key: string) => void;
    customError: (key: string, error?: Error) => void;
    customWarning: (key: string, error?: Error) => void;
}

type FlashMessageUtilType = (__t: Translate) => FlashMessages;

const FlashMessageUtil: FlashMessageUtilType = (__t: Translate) => {
    const message = (key: string) =>
        EventHandler.trigger('FlashMessage::create', {
            type: 'success',
            title: __t(`notice.success.${key}`),
        });

    const errorMessage = (key: string, error?: Error) =>
        EventHandler.trigger('FlashMessage::create', {
            type: 'error',
            title: __t(`notice.error.${key}`),
            message: error?.message || '',
        });

    const warningMessage = (key: string, error?: Error) =>
        EventHandler.trigger('FlashMessage::create', {
            type: 'warning',
            title: __t(`notice.warning.${key}`),
            message: error?.message || '',
        });

    return {
        deleteSuccess: () => message('delete'),
        deleteError: (error?: Error) => errorMessage('delete', error),
        createSuccess: () => message('create'),
        createError: (error?: Error) => errorMessage('create', error),
        updateSuccess: () => message('edit'),
        updateError: (error?: Error) => errorMessage('edit', error),
        customSuccess: message,
        customError: errorMessage,
        customWarning: warningMessage,
    };
};

export default FlashMessageUtil;
